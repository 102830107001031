import { Component, Vue } from 'vue-property-decorator'
import { Service } from 'typedi'
import { placeholder } from '@/bootstrap/global/constants'

@Service()
@Component({
  name: 'BadgesMixin'
})

export default class BadgesMixin extends Vue {
  buildDataPreview (svg: string, fieldData: Record<string, any>, optionData: Record<string, any>): string {
    const optionGroupSelections = optionData.reduce((selections, selectedOption) => {
      selections[selectedOption.option_group.uuid] = selectedOption.option.name
      return selections
    }, {})
    const svgContainer = document.createElement('div')
    svgContainer.innerHTML = svg
    const textElements = svgContainer.getElementsByTagName('text')
    svgContainer.style.position = 'absolute'
    svgContainer.style.bottom = '0'
    svgContainer.style.zIndex = '-100'
    svgContainer.style.opacity = '0'
    const svgDomElement = document.body.appendChild(svgContainer)
    const maxWidth = svgDomElement.clientWidth * 0.85
    const fieldRegex = /{{\s*([-\w]+)\s*}}/g

    for (const textElement of textElements) {
      let maxTextWidth: undefined | string
      if ('maxTextWidth' in textElement.dataset) {
        maxTextWidth = textElement.dataset.maxTextWidth
      }
      if (textElement.textContent) {
        const match = [...textElement.textContent.matchAll(fieldRegex)]
        for (const [placeholder, field] of match) {
          let fieldValue = optionGroupSelections[field] ?? (fieldData[field] instanceof Array ? fieldData[field][0] : fieldData[field])
          if (fieldValue === null || fieldValue === undefined) {
            fieldValue = field === 'certificate_id' ? placeholder : ''
          }
          textElement.textContent = textElement.textContent.replace(placeholder, fieldValue)
        }
        const targetWidth = maxTextWidth ?? maxWidth
        while (this.getTextWidth(textElement.textContent, this.getCanvasFont(textElement)) > targetWidth) {
          textElement.style.fontSize = (parseInt(textElement.style.fontSize) - 2) + 'px'
        }
      }
    }

    svgDomElement.remove()
    return this.insertPlaceholderQrCode(svgContainer.innerHTML)
  }

  private insertPlaceholderQrCode (svg: string): string {
    // setup parser for svg string.
    const parser = new DOMParser()
    const svgDOM = parser.parseFromString(svg, 'image/svg+xml')
    // grab the qr_code images
    const qrCodes = svgDOM.querySelectorAll('#qr_code')
    if (qrCodes) {
      for (let i = 0; i < qrCodes.length; i++) {
        qrCodes[i].setAttribute('xlink:href', placeholder)
      }
      return svgDOM.querySelector('svg')?.outerHTML ?? svg
    } else {
      return svg
    }
  }

  getSvgPreview (svg: string): string {
    const previewSvgWrap = document.createElement('div') as HTMLElement
    previewSvgWrap.innerHTML = svg
    if (previewSvgWrap.children[0]?.children) {
      for (let i = 0; i < previewSvgWrap.children[0].children.length; i++) {
        const layer = previewSvgWrap.children[0].children[i]
        if (layer.id === 'nonPrintLayer') {
          const nonPrintLayer = layer as HTMLElement
          if (nonPrintLayer.dataset.visible === '1') {
            nonPrintLayer.style.visibility = 'visible'
          }
        }
        for (let j = 0; j < layer.children.length; j++) {
          for (let k = 0; k < layer.children[j].children.length; k++) {
            const elementContainer = layer.children[j].children[k]
            const element = elementContainer.children[0] as HTMLElement
            if (element.dataset.customTextLabel && element.dataset.customTextLabel.includes('Option Group')) {
              if (this.isValidUUID(element.innerHTML.replace(/\{|\}/g, ''))) {
                element.innerHTML = `{{${element.dataset.customTextLabel}}}`
              }
            }
          }
        }
      }
      const previewSvg = previewSvgWrap.innerHTML
      return previewSvg
    } else {
      return svg
    }
  }

  getSvgPreviewUnaltered (svg: string) {
    const previewSvgWrapClone = document.createElement('div') as HTMLElement
    previewSvgWrapClone.innerHTML = svg
    if (previewSvgWrapClone.children[0]?.children) {
      for (let i = 0; i < previewSvgWrapClone.children[0].children.length; i++) {
        const layer = previewSvgWrapClone.children[0].children[i]
        if (layer.id === 'nonPrintLayer') {
          const nonPrintLayer = layer as HTMLElement
          if (nonPrintLayer.dataset.visible === '1') {
            nonPrintLayer.style.visibility = 'visible'
          }
        }
      }
    }
    const previewSvgUnaltered = previewSvgWrapClone.innerHTML
    return previewSvgUnaltered
  }

  isValidUUID (uuid) {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
    return regex.test(uuid)
  }

  encodeHTMLEntities (text: string) {
    const textArea = document.createElement('textarea')
    textArea.innerText = text
    return textArea.innerHTML
  }

  /**
   * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
   *
   * @param {String} text The text to be rendered.
   * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
   *
   * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
   */
  getTextWidth (text: string, font: string): number {
    // re-use canvas object for better performance
    const thisFunction = this.getTextWidth as any
    const canvas = thisFunction.canvas || (thisFunction.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  getCssStyle (element: Element, prop: string) {
    return window.getComputedStyle(element, null).getPropertyValue(prop)
  }

  getCanvasFont (el: Element = document.body) {
    const fontWeight = this.getCssStyle(el, 'font-weight') || 'normal'
    const fontSize = this.getCssStyle(el, 'font-size') || '16px'
    const fontFamily = this.getCssStyle(el, 'font-family') || 'Times New Roman'

    return `${fontWeight} ${fontSize} ${fontFamily}`
  }

  get supportedAverySizes () {
    return ['3-3/8" x 2-1/3"', '3-1/2" x 2-1/4"', '4" x 2"', '4" x 3"']
  }

  get averyTemplates () {
    const templates = [
      {
        number: '5152',
        size: '3-3/8" x 2-1/3"'
      },
      {
        number: '5395',
        size: '3-3/8" x 2-1/3"'
      },
      {
        number: '8395',
        size: '3-3/8" x 2-1/3"'
      },
      {
        number: '5390',
        size: '3-1/2" x 2-1/4"'
      },
      {
        number: '5163',
        size: '4" x 2"'
      },
      {
        number: '5263',
        size: '4" x 2"'
      },
      {
        number: '5523',
        size: '4" x 2"'
      },
      {
        number: '5923',
        size: '4" x 2"'
      },
      {
        number: '5963',
        size: '4" x 2"'
      },
      {
        number: '8463',
        size: '4" x 2"'
      },
      {
        number: '5384',
        size: '4" x 3"'
      },
      {
        number: '5392',
        size: '4" x 3"'
      },
      {
        number: '74459',
        size: '4" x 3"'
      },
      {
        number: '74536',
        size: '4" x 3"'
      },
      {
        number: '74540',
        size: '4" x 3"'
      },
      {
        number: '74541',
        size: '4" x 3"'
      }
    ]
    return templates
  }
}
